import React from 'react';
import logo from '../assets/logo.svg';

const Agreements = () => {
  return (
    <>
      <header style={{ textAlign: 'center', padding: 30 }}>
        <a href="/"><img src={logo} alt="road24" /></a>
      </header>
      <div className="privacy" style={{ padding: '20px 60px 40px' }}>
        <p><strong>“ROAD24” ФОЙДАЛАНУВЧИЛАРИНИНГ ШАХСИЙ МАЪЛУМОТЛАРИНИ ҚАЙТА ИШЛАШГА РУХСАТ БЕРИШ ТЎҒРИСИДАГИ КЕЛИШУВ</strong></p>
          <p>Шахсий маълумотларни қайта ишлаш бўйича ушбу келишув (кейинги ўринларда Махфийлик сиёсати деб юритилади) “Road24” мобил иловаси (кейинги ўринларда “Road24” ёки “Road24.uz” веб-сайти деб юритилади) фойдаланувчиси ҳақида ушбу мобил илова ва унинг функцияларидан фойдаланиш вақтида олиниши мумкин бўлган барча маълумотларга нисбатан қўлланилади.</p>
          <p></p>
          <p><strong>1. АТАМАЛАР ТАЪРИФИ</strong></p>
        <p>1.1. Шахсга доир маълумотлар — муайян жисмоний шахсга тааллуқли бўлган ёки уни идентификация қилиш имконини берадиган, электрон тарзда, қоғозда ва (ёки) бошқа моддий жисмда қайд этилган ахборот;</p>
        <p>Шахсга доир маълумотлар субекти (субект) — шахсга доир маълумотлар ўзига тааллуқли бўлган жисмоний шахс;</p>
        <p>Шахсга доир маълумотлар базаси — таркибида шахсга доир маълумотлар мавжуд бўлган ахборот тизими тарзидаги маълумотлар базаси;</p>
        <p>1.2. Шахсга доир маълумотларга ишлов бериш — шахсга доир маълумотларни йиғиш, тизимлаштириш, сақлаш, ўзгартириш, тўлдириш, улардан фойдаланиш, уларни бериш, тарқатиш, узатиш, эгасизлантириш ва йўқ қилиш бўйича бир ҳаракатни ёки ҳаракатлар мажмуини амалга ошириш;</p>
        <p>1.3. «Шахсий маълумотларнинг махфийлиги» - Оператор ёки шахсий маълумотларга кириш ҳуқуқига эга бўлган бошқа шахс учун шахсий маълумотлар субектининг розилигисиз ёки бошқа қонуний асослар мавжуд бўлмаган ҳолда уларни тарқатишга йўл қўймаслик тўғрисидаги мажбурий талаб.</p>
        <p>1.4. “Роад24 фойдаланувчиси” – интернет орқали веб-сайтга (Роад24.уз), мобил иловага кириш имконига эга бўлган ва “Роад24” маълумотлари, материаллари ва маҳсулотларидан фойдаланувчи шахс.</p>
        <p>1.5. “Жарима тўлов хизматлари” – бу фойдаланувчи мобил иловада ёки веб-сайтда оладиган маълумот асосида “Роад24” да қўлланиладиган тўлов тизими орқали тўлайдиган маҳсулотдир.</p>
        <p>1.6. “Шахсий маълумотлар оператори” ёки “Оператор” – “Роад24” мобил иловаси мулкдори сифатида “КАШ АПП” масъулияти чекланган жамияти сифатида.</p>
        <p>1.7. Томонлар Фойдаланувчи томонидан ҳамма учун очиқ бўлган шахсий маълумотларни ўз ичига олган шахсий маълумотлар базалари рўйхатдан ўтказилмаслигини тасдиқлайди.</p>
        <p>1.8. Шахсий маълумотлар тасдиқлангандан сўнг, фойдаланувчи ўз маълумотларини қайта ишлашга розилигини беради ва уни ҳамма учун очиқ қилади.</p>
        <p><strong>2. УМУМИЙ ҚОИДАЛАР</strong></p>
        <p>2.1. Фойдаланувчининг Роад24.уз Сайтидан ёки иловасидан фойдаланишни бошлаши ушбу келишув шартларини ва Фойдаланувчининг шахсий маълумотларини қайта ишлаш шартларини қабул қилганлигини англатади.</p>
        <p>2.2. Мазкур келишув шартларига рози бўлмаган тақдирда Фойдаланувчи «Роад24» мобил иловаси ва Веб-сайтдан () фойдаланишни тўхтатиши шарт. Фойдаланувчи томонидан «Роад24» мобил иловасида рўйхатдан ўтиш шахсий маълумотлар субектининг ушбу келишув шартларига розилигини билдиради.</p>
        <p>2.3. Ушбу ҳужжат фойдаланувчи сайтда рўйхатдан ўтишда, ахборот электрон почтасига обуна бўлганда ёки буюртма беришда операторнинг илтимосига биноан тақдим этадиган шахсий маълумотларни қайта ишлаш бўйича томонларнинг ҳуқуқ ва мажбуриятларини белгилайди.</p>
        <p>2.4. Ушбу Келишув бўйича қайта ишлашга рухсат берилган шахсий маълумотлар фойдаланувчи томонидан «Роад24» мобил иловасидаги шаклларни тўлдириш орқали тақдим этилади ва қуйидаги маълумотларни ўз ичига олади:</p>
        <p>2.4.1. Ф.И.О ва фойдаланувчи паспортининг серияси;</p>
        <p>2.4.2. Фойдаланувчининг  телефон рақами;</p>
        <p>2.4.3. Фойдаланувчининг электрон почта манзили (электрон почта);</p>
        <p>2.4.4. Фойдаланувчи жинси;</p>
        <p>2.4.5. Фойдаланувчининг туғилган санаси;</p>
        <p>2.4.6 Фойдаланувчининг автомобили ҳақидаги маълумотлар, хусусан:</p>
        <p>1. Автомобилнинг давлат рақами (ҳудудий коди, серияси, давлат рақами ва давлат идентификатори);</p>
        <p>2. Техник маълумотлар пасспорт серияси ва унинг рақами;</p>
        <p>3. Фойдаланувчи автомобилининг маркаси ва модели.</p>
        <p>2.4.6.1. «Роад24» мобил иловасининг «Мяуто» бўлимида кўрсатилган фойдаланувчининг автомобили ҳақидаги бошқа маълумотлар, хусусан:</p>
        <p>1. Фойдаланувчининг автомашинасини суғурта қилиш тўғрисидаги маълумотлар;</p>
        <p>2. Фойдаланувчининг автомобилини техник кўрикдан ўтказиш ва техник кўрикдан ўтказилган жой ҳақида маълумот. Тех.Текшириш;</p>
        <p>3. Ушбу транспорт воситасидан фойдаланиш ҳуқуқига эга бўлган шахсларни ва бошқа маълумотларни кўрсатган ҳолда фойдаланувчининг автомашинасига ишончнома тўғрисидаги маълумотлар (ишончноманинг амал қилиш муддати, ишончнома тури);</p>
        <p>4. Қўшимча маълумотлар билан фойдаланувчининг автомобилида ойналар тусини ўзгартириш мавжудлиги тўғрисидаги маълумот (амал қилиш муддати, ойналар тусини ўзгартириш тури ва бошқа маълумотлар). Ушбу имкониятни тақдим этиш учун Оператор бошқа ташкилотлар, жумладан мй.гов.уз  билан алоҳида алоқа ўрнатади.</p>
        <p>5. Фойдаланувчининг автомобилининг машина мойини қўшимча маълумот билан алмаштириш тўғрисида маълумот;</p>
        <p>2.4.7. Фойдаланувчининг автомобили учун жарималар тўғрисидаги маълумотлар қуйидаги маълумотларни кўрсатади:</p>
        <p>1. Фойдаланувчининг Автомобили учун олинган жарималар сони;</p>
        <p>2. Тўланган ва тўланмаган жарималар тарихи;</p>
        <p>3. Жарима тафсилотлари қуйидаги  маълумотларни ўз ичига олади:</p>
        <p>а. Қўйилган жарималар қарори рақамлари;</p>
        <p>б. Фойдаланувчи автомобилининг йўл ҳаракати қоидаларини бузган пайтдаги тасвирлари, автомобилнинг давлат рақами тасвири;</p>
        <p>c. Фойдаланувчи автомобилининг маркаси;</p>
        <p>д. Ўзбекистон Республикаси Маъмурий жавобгарлик тўғрисидаги кодексининг моддаларига асосан ҳуқуқбузарлик тавсифи;</p>
        <p>е. Йўл ҳаракати қоидалари бузилган жойининг аниқ манзилни кўрсатиш;</p>
        <p>ф. Йўл ҳаракати қоидаларини бузилган вақти тўғрисидаги маълумотлар;</p>
        <p>г. Ўзбекистон Республикасининг Маъмурий жавобгарлик тўғрисидаги кодексининг моддаси;</p>
        <p>ҳ. Жарималар қиймати давлат валютасида кўрсатиш (сўмда);</p>
        <p>и. Рақамли ҳужжат ПДФ форматида ва юқорида тавсифланган барча маълумотларни ўз ичига олади.</p>
        <p>ж. Жарима мажудлиги ҳақида маълумотлар.</p>
        <p>2.4.8. «Роад24» мобил иловасининг қуйидаги функсияларидан фойдаланиш учун фойдаланувчининг геолокация маʼлумотлари:</p>
        <p>1. Бошқа ҳайдовчилар томонидан йўл ҳаракати қоидаларини бузганлиги тўғрисида Гео жойлашуви, ушбу қоидабузарлик қайд этилган видеофайл, ушбу ҳодиса содир бўлган сана ва вақтни кўрсатган ҳолда баённома/қарор юбориш;</p>
        <p>2.4.9. Қуйидаги функсиялар учун молиявий операцияларни амалга ошириш учун фойдаланувчининг банк картаси реквизитлари:</p>
        <p>1. Қисман ёки тўлиқ тўлаш имконияти билан Фойдаланувчининг Автомобили учун қайд этилган йўл ҳаракати қоидаларини бузганлик учун жарима тўлаш. ҳақидаги маʼлумот фойдаланувчига “Мяуто > Жарималар > Тоʻланмаган > Тоʻловлар тарихи” боʻлимида, шунингдек, “Роад24” мобил иловасининг “Огоҳлантиришлар” боʻлимида мавжуд боʻлади.</p>
        <p>2. “Роад24” мобил иловасининг “Хисоботлар” бўлимида йўл ҳаракати қоидабузарлигини қайд этганлик учун мукофот олиш.</p>
        <p>2.5. Фойдаланувчига ундан фойдаланиш имкониятини бериш учун Оператор бошқа ташкилотлар, жумладан мй.гов.уз билан алоҳида алоқа ўрнатишга ҳақли.</p>
        <p>2.6. Ушбу илова орқали опсионлар (рухсатномалар) харид қилинган тақдирда, Фойдаланувчи Ягона интерактив давлат хизматлари портали хизматлари нархи ва Оператор харажатларидан иборат бўлган харид нархини қабул қилади ва унга рози бўлади.</p>
        <p></p>
        <p><strong>3. ШАХСИЙ МАъЛУМОТЛАРНИ ЙИҒИШ МАҚСАДЛАРИ</strong></p>
        <p>3.1. Оператор фойдаланувчининг шахсий маълумотларидан қуйидаги мақсадларда фойдаланиш ҳуқуқига эга:</p>
        <p>3.1.1. «Роад24» мобил иловасида рўйхатдан ўтган Фойдаланувчини идентификациялаш, бошқа ҳайдовчилар томонидан йўл ҳаракати қоидаларини бузганликлари тўғрисида баённома тузиш ва “Роад24” мобил иловаси доирасидаги бошқа ҳаракатларни амалга ошириш.</p>
        <p>3.1.2. Фойдаланувчига «Роад24» мобил иловаси ва веб-сайтининг () шахсий маълумотларига киришни таъминлаш.</p>
        <p>3.1.3. Фойдаланувчи билан фикр-мулоҳазаларни ўрнатиш, шу жумладан «Роад24» мобил иловаси ва Веб-сайтдан ().фойдаланиш бўйича билдиришномалар, сўровлар юбориш, хизматлар кўрсатиш ва Фойдаланувчининг ҳисоботларини қайта ишлаш.</p>
        <p>3.1.4. Агар Фойдаланувчи аккаунт яратишга рози бўлса, «Роад24» мобил иловаси ва Веб-сайт ().қисмларидан фойдаланиш учун шахсий аккаунт яратиш.</p>
        <p>3.1.5. Фойдаланувчига махсус таклифлар, Оператор маҳсулотлари ва хизматлари, Оператор ҳамкорлари ҳақида маълумот, ахборот бюллетенларини тақдим этиш.</p>
        <p>3.1.6. Фойдаланувчига суғурта ва ойналар тусини ўзгартириш бўйича қўшимча хизматларни тақдим этиш ва ҳ.к.</p>
        <p>3.2. Фойдаланувчини «Роад24» мобил иловасида ва веб-сайтда () роʻйхатдан оʻтказгандан соʻнг, Оператор ушбу келишувда назарда тутилган ҳаракатларни амалга ошириш учун Фойдаланувчининг шахсий маʼлумотларидан фойдаланиш ҳуқуқига эга.</p>
        <p>3.3. Фойдаланувчи ушбу илова орқали ойналар тусини ўзгартириш, суғурта полиси ва бошқа рухсатномаларни сотиб олиши, жарималарни тўлаши мумкинлигини қабул қилади. Ўз навбатида, Оператор фойдаланувчи ва Ягона интерактив давлат хизматлари портали ўртасидаги платформага айланади.</p>
        <p></p>
        <p><strong>4. ШАХСИЙ МАъЛУМОТЛАРНИ ҚАЙТА ИШЛАШ УСУЛЛАРИ ВА ШАРТЛАРИ</strong></p>
        <p>4.1. Фойдаланувчининг шахсий маълумотларига ишлов бериш муддатсиз, ҳар қандай қонуний йўл билан, шу жумладан автоматлаштириш воситаларидан фойдаланган ҳолда шахсий маълумотларнинг ахборот тизимларида ёки бундай воситалардан фойдаланмасдан амалга оширилади.</p>
        <p>4.2. Фойдаланувчи Оператор шахсий маълумотларни фақат ушбу келишувда назарда тутилган қайта ишлаш мақсадлари учун учинчи шахсларга ўтказиш ҳуқуқига эга эканлигига рози бўлади.</p>
        <p>4.3. Оператор фойдаланувчининг шахсий маълумотларини рухсатсиз ёки тасодифий кириш, йўқ қилиш, ўзгартириш, блоклаш, нусхалаш, тарқатиш, шунингдек учинчи шахсларнинг бошқа ноқонуний ҳаракатларидан ҳимоя қилиш учун зарур ташкилий ва техник чораларни кўради.</p>
        <p></p>
        <p><strong>5. КEЛИШУВНИ БEКОР ҚИЛИШ</strong></p>
        <p>Фойдаланувчи Телеграмда Телеграм: ҳттпс://т.ме/»Роад24»ёрдам Операторга билдиришнома юбориш орқали шахсий маълумотларни қайта ишлашга розилигини қайтариб олишга ҳақли, Фойдаланувчининг шахсий маълумотлари Оператор бажариши учун зарур бўлган ҳоллар бундан мустасно.</p>
        <p></p>
        <p><strong>6. ТОМОНЛАРНИНГ ЖАВОБГАРЛИГИ</strong></p>
        <p>6.1. Ўз мажбуриятларини айбдор равишда бажармаган Оператор шахсий маълумотлардан ноқонуний фойдаланиш муносабати билан Фойдаланувчига етказилган ҳақиқий зарар учун Ўзбекистон Республикаси қонунчилигига мувофиқ жавобгар бўлади. Бундай ҳолда, Фойдаланувчига етказилган ҳақиқий зарар ҳужжатлар асосида исботланиши шарт.</p>
        <p>6.2. Оператор Фойдаланувчи олдида: “Роад24” мобил иловасидан ва Веб-сайтдан () фойдаланиш ёки фойдалана олмаслик оқибатида кўрилган зарарлар; фойдаланувчининг алоқаларига рухсатсиз кирилганда; Сайтдаги ҳар қандай учинчи томоннинг хатти-ҳаракатлари  учун жавобгар эмас</p>
        <p>6.3. Оператор Фойдаланувчи томонидан «Роад24» мобил иловаси ва Веб-сайтда () жойлаштирилган ҳар қандай маълумотлар учун жавобгар эмас.</p>
        <p></p>
        <p><strong>7. ҚЎШИМЧА ШАРТЛАР</strong></p>
        <p>7.1. Оператор фойдаланувчининг розилигисиз ушбу келишувга ва Махфийлик сиёсатига ўзгартиришлар киритиш ҳуқуқига эга.</p>
        <p>7.2. Махфийлик сиёсати «Роад24» мобил иловасида ва веб-сайтида () жойлаштирилган пайтдан бошлаб кучга киради.</p>
        <p></p>
        <p></p>
      </div>
    </>
  );
}

export default Agreements;
